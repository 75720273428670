import React from "react";
import _ from "lodash";
import { A4Page } from "~/components/print";
import { formats } from "~/helper";
import { Container } from "~/pages/inspection/reports/blend/helper";
import RenderHeader from "~/pages/inspection/reports/blend/header";
import RenderTable from "~/pages/inspection/reports/blend/table";
import {
  CarContainer,
  Markup,
} from "~/pages/inspection/reports/styles";
import RenderFooter from "~/pages/inspection/reports/blend/footer";
import car from "~/assets/car.svg";

const status = {
  danger: {
    color: "#e91e1e",
    text: "Repintura e/ou massa"
  },
  warning: {
    color: "#ffc107",
    text: "Repintura"
  },
  ok: {
    color: "#42c140",
    text: "Pintura original"
  }
};

const getOptions = key => {
  if (!status) return { color: "#fff", text: "" };
  const result = _.get(status, key, '');
  return result;
};

function Paint({ model = {}, getPage = _.noop }) {
  const paints = _.pick(
    model.paints,
    "hood",
    "left_front_fender",
    "left_front_door",
    "left_rear_door",
    "left_rear_fender",
    "rear",
    "right_rear_fender",
    "right_rear_door",
    "right_front_door",
    "right_front_fender",
    "ceiling"
  );
  const companyConferi = _.get(model, "company_conferi", {});
  const brand =
    _.get(companyConferi, "bin.marca") ||
    _.get(companyConferi, "respostaEstadual.marca") ||
    "";

  return (
    <A4Page>
      <Container>
        <RenderHeader
          accountId={_.get(model, "customer.parent.id")}
          position={model.position}
          board={model.board}
          brand={brand}
        />

        <RenderTable
          title="TESTE ESPESSURA DA CAMADA DE TINTA. (CORTESIA)"
          data={[
            {
              label: "Data da vistoria",
              value: formats.dateTimeZone(
                _.get(model, "surveyAt"),
                "dd/MM/yyyy HH:mm"
              )
            },
            {
              label: "Número",
              value: formats.fill(_.get(model, "id"), 10, "0")
            },
            {
              label: "Data da solicitação",
              value: formats.dateTimeZone(
                _.get(model, "createdAt"),
                "dd/MM/yyyy HH:mm"
              )
            },
            {
              label: "Vistoriador",
              value: _.upperCase(_.get(model, "technician.name"))
            }
          ]}
        />

        <RenderTable
          title="DADOS DO CLIENTE"
          data={[
            {
              label: "Nº Documento",
              value: formats.cnpj_cpf(_.get(model, "customer.identify"))
            },
            { label: "Nome", value: _.upperCase(_.get(model, "customer.name")) }
          ]}
        />
        <br />

        <CarContainer>
          <img
            src={car}
            style={{
              width: 800,
              height: 800,
              border: "none",
              alignSelf: "center",
              transform: "rotate(90deg)"
            }}
            alt=""
          />

          {/* Capo */}
          <Markup
            background={getOptions(paints["hood"]).color}
            top="-2px"
            left="350px"
          >
            <div>{getOptions(paints["hood"]).text}</div>
            <p>
              Capô
            </p>
          </Markup>

          {/* Paralamas esquerdo dianteiro */}
          <Markup
            background={getOptions(paints["left_front_fender"]).color}
            top="220px"
            left="110px"
          >
            <div>{getOptions(paints["left_front_fender"]).text}</div>
            <p>
              Para-Lama
              <br />
              Dianteiro esquerdo
            </p>
          </Markup>

          {/* Porta esquerda dianteira */}
          <Markup
            background={getOptions(paints["left_front_door"]).color}
            top="358px"
            left="130px"
          >
            <div>{getOptions(paints["left_front_door"]).text}</div>
            <p>
              Porta dianteira
              <br />
              Esquerda
            </p>
          </Markup>

          {/* Porta esquerda traseira */}
          <Markup
            background={getOptions(paints["left_rear_door"]).color}
            top="493px"
            left="135px"
          >
            <div>{getOptions(paints["left_rear_door"]).text}</div>
            <p>
              Porta traseira
              <br />
              Esquerda
            </p>
          </Markup>

          {/* Paralamas esquerdo traseiro */}
          <Markup
            background={getOptions(paints["left_rear_fender"]).color}
            top="604px"
            left="120px"
          >
            <div>{getOptions(paints["left_rear_fender"]).text}</div>
            <p>
              Para-lama
              <br />
              Traseiro esquerdo
            </p>
          </Markup>

          {/* Teto */}
          <Markup
            background={getOptions(paints["ceiling"]).color}
            top="768px"
            left="225px"
          >
            <div>{getOptions(paints["ceiling"]).text}</div>
            <p>Teto</p>
          </Markup>

          {/* Traseira */}
          <Markup
            background={getOptions(paints["rear"]).color}
            top="772px"
            left="350px"
          >
            <div>{getOptions(paints["rear"]).text}</div>
            <p>Traseira</p>
          </Markup>

          {/* Paralamas direito dianteiro */}
          <Markup
            background={getOptions(paints["right_front_fender"]).color}
            top="220px"
            left="571px"
          >
            <div>{getOptions(paints["right_front_fender"]).text}</div>
            <p>
              Para-Lama
              <br />
              Dianteiro esquerdo
            </p>
          </Markup>

          {/* Porta direita traseira */}
          <Markup
            background={getOptions(paints["right_front_door"]).color}
            top="359px"
            left="571px"
          >
            <div>{getOptions(paints["right_front_door"]).text}</div>
            <p>
              Porta dianteira
              <br />
              Direita
            </p>
          </Markup>

          {/* Porta traseira direita */}
          <Markup
            background={getOptions(paints["right_rear_door"]).color}
            top="493px"
            left="571px"
          >
            <div>{getOptions(paints["right_rear_door"]).text}</div>
            <p>
              Porta traseira
              <br />
              Direita
            </p>
          </Markup>

          {/* Paralamas direito traseiro */}
          <Markup
            background={getOptions(paints["right_rear_fender"]).color}
            top="604px"
            left="571px"
          >
            <div>{getOptions(paints["right_rear_fender"]).text}</div>
            <p>
              Lateral traseira
              <br />
              Direita
            </p>
          </Markup>
        </CarContainer>

        <RenderFooter model={model} page={getPage()} />
      </Container>
    </A4Page>
  );
}

export default Paint;
